import TextField, { TextFieldProps } from "@mui/material/TextField";

export interface CustomTextFieldProps
  extends Omit<TextFieldProps, "helperText"> {
  helperText?: string;
}

function CustomTextField(props: CustomTextFieldProps) {
  return <TextField {...props} />;
}
CustomTextField.defaultProps = {
  variant: "outlined",
};
export default CustomTextField;
