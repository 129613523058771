import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// import { signOutSuccess } from '../UserProfile/slice';
import { resetUser } from 'src/auth/utils/resetUser'
import { NetworkState } from '../../constants/network-state'
import ApplicationGroupApiService from '../../services/switch-rest-api/rest-application-group.service'
import { toast } from 'react-toastify'
import { ApplicationGroup } from 'switch-shared/modules/crud/application-group/application-group.entity'
import { UpdateDto } from 'switch-shared/modules/crud/base/base.service'

const applicationGroupApiService = new ApplicationGroupApiService()

export const fetchApplicationGroups = createAsyncThunk(
    'applicationGroups/getAppGroups',
    async (params: { applicationGroupId?: string }) => {
        try {
            const res = await applicationGroupApiService.getAppGroups({
                page: 0,
                limit: 100,
            })

            return res
        } catch (error: any) {
            throw new Error(error.message)
        }
    }
)

export const createApplicationGroup = createAsyncThunk(
    'applicationGroups/createApplicationGroup',
    async (newAppObject: { name: string }) => {
        try {
            const res =
                await applicationGroupApiService.createAppGroup(newAppObject)
            return res
        } catch (error: any) {
            throw new Error(error.message)
        }
    }
)

export const deleteApplicationGroup = createAsyncThunk(
    'applicationGroups/deleteApplicationGroup',
    async (id: string) => {
        try {
            const res = await applicationGroupApiService.deleteAppGroup(id)
            return {
                ...res,
                _id: id,
            }
        } catch (error: any) {
            throw new Error(error.message)
        }
    }
)

export const updateApplicationGroup = createAsyncThunk(
    'applicationGroups/updateApplicationGroup',
    async ({
        id,
        appGroup,
    }: {
        id: string
        appGroup: UpdateDto<ApplicationGroup>
    }) => {
        try {
            await applicationGroupApiService.updateAppGroup(id, appGroup)
            return {
                ...appGroup,
                _id: id,
            }
        } catch (error: any) {
            throw new Error(error.message)
        }
    }
)

interface applicationGroupSliceInterface {
    applicationGroupNetworkStatus: {
        applicationGroups: NetworkState
        isUpdatingApplicationGroup: NetworkState
        isCreatingApplicationGroup: NetworkState
        isDeletingAppGroup: NetworkState
    }
    errors: {
        isUpdatingApplicationGroup: string | undefined
        isCreatingApplicationGroup: string | undefined
        isDeletingAppGroup: string | undefined
    }
    manualApplicationGroupChange: boolean
    applicationGroups: ApplicationGroup[]
    applicationGroupsCount: number
    locallySelectedApplicationGroupId: string
}

const initialState: applicationGroupSliceInterface = {
    applicationGroupNetworkStatus: {
        applicationGroups: NetworkState.NOT_STARTED,
        isUpdatingApplicationGroup: NetworkState.NOT_STARTED,
        isCreatingApplicationGroup: NetworkState.NOT_STARTED,
        isDeletingAppGroup: NetworkState.NOT_STARTED,
    },
    errors: {
        isUpdatingApplicationGroup: '',
        isCreatingApplicationGroup: '',
        isDeletingAppGroup: '',
    },
    applicationGroups: [],
    applicationGroupsCount: 0,
    locallySelectedApplicationGroupId: '',
    manualApplicationGroupChange: false,
}

const applicationGroupSlice = createSlice({
    name: 'applicationGroups',
    initialState,
    reducers: {
        updateSelectedApplicationGroupId(state, action) {
            localStorage.removeItem('locallySelectedApplicationId')
            localStorage.setItem(
                'locallySelectedApplicationGroupId',
                JSON.stringify(action.payload)
            )
            state.manualApplicationGroupChange = true
            state.locallySelectedApplicationGroupId = action.payload
        },
        resetAppGroupUpdateAndCreateState(state) {
            state.applicationGroupNetworkStatus.isUpdatingApplicationGroup =
                NetworkState.NOT_STARTED
            state.applicationGroupNetworkStatus.isCreatingApplicationGroup =
                NetworkState.NOT_STARTED
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchApplicationGroups.pending, (state) => {
                state.applicationGroupNetworkStatus.applicationGroups =
                    NetworkState.PENDING
            })
            .addCase(fetchApplicationGroups.fulfilled, (state, action) => {
                const applicationGroups = action.payload?.value.data
                const locallySelectedApplicationGroupId = localStorage.getItem(
                    'locallySelectedApplicationGroupId'
                )
                    ? JSON.parse(
                          localStorage.getItem(
                              'locallySelectedApplicationGroupId'
                          )!
                      )
                    : applicationGroups.length
                      ? applicationGroups[0]._id
                      : null
                state.locallySelectedApplicationGroupId =
                    locallySelectedApplicationGroupId
                state.applicationGroups = applicationGroups
                state.applicationGroupsCount = action.payload?.value.count
                state.applicationGroupNetworkStatus.applicationGroups =
                    NetworkState.SUCCESS
            })
            .addCase(fetchApplicationGroups.rejected, (state) => {
                toast.error('Error fetching Application Groups')
                state.applicationGroupNetworkStatus.applicationGroups =
                    NetworkState.ERROR
            })
            .addCase(createApplicationGroup.pending, (state) => {
                state.applicationGroupNetworkStatus.isCreatingApplicationGroup =
                    NetworkState.PENDING
            })
            .addCase(createApplicationGroup.fulfilled, (state, action) => {
                toast.success('Group successfully created')
                state.applicationGroupNetworkStatus.isCreatingApplicationGroup =
                    NetworkState.SUCCESS
                state.applicationGroupNetworkStatus.applicationGroups =
                    NetworkState.NOT_STARTED
            })
            .addCase(createApplicationGroup.rejected, (state) => {
                toast.error('Error creating group')
                state.applicationGroupNetworkStatus.isCreatingApplicationGroup =
                    NetworkState.ERROR
            })
            .addCase(deleteApplicationGroup.pending, (state) => {
                state.applicationGroupNetworkStatus.isDeletingAppGroup =
                    NetworkState.PENDING
            })
            .addCase(deleteApplicationGroup.fulfilled, (state, action) => {
                const storedId = localStorage.getItem(
                    'locallySelectedApplicationGroupId'
                )

                // If the currently selected application is deleted
                if (storedId === JSON.stringify(action.payload._id)) {
                    localStorage.removeItem('locallySelectedApplicationGroupId')
                }

                state.applicationGroupNetworkStatus.isDeletingAppGroup =
                    NetworkState.SUCCESS
                state.applicationGroupNetworkStatus.applicationGroups =
                    NetworkState.NOT_STARTED
            })
            .addCase(deleteApplicationGroup.rejected, (state) => {
                state.applicationGroupNetworkStatus.isDeletingAppGroup =
                    NetworkState.ERROR
                state.locallySelectedApplicationGroupId = ''
            })
            .addCase(updateApplicationGroup.pending, (state) => {
                state.applicationGroupNetworkStatus.isUpdatingApplicationGroup =
                    NetworkState.PENDING
            })
            .addCase(updateApplicationGroup.fulfilled, (state, action) => {
                state.applicationGroups = state.applicationGroups.map(
                    (applicationGroup) =>
                        applicationGroup._id === action.payload?._id
                            ? ({
                                  ...applicationGroup,
                                  ...action.payload,
                              } as any)
                            : applicationGroup
                )

                state.applicationGroupNetworkStatus.isUpdatingApplicationGroup =
                    NetworkState.SUCCESS
            })
            .addCase(updateApplicationGroup.rejected, (state) => {
                state.applicationGroupNetworkStatus.isUpdatingApplicationGroup =
                    NetworkState.ERROR
            })
            .addCase(resetUser.fulfilled, (state, action) => {
                localStorage.removeItem('locallySelectedApplicationGroupId')
                state = initialState
            })
    },
})

export const {
    updateSelectedApplicationGroupId,
    resetAppGroupUpdateAndCreateState,
} = applicationGroupSlice.actions

export default applicationGroupSlice.reducer
