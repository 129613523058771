import { Help as HelpIcon } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useState } from 'react'
import StepperDialogMobile from 'src/components/Dialogs/StepperDialogMobile'
import image_one from 'src/assets/intro/one.png'
import image_two from 'src/assets/intro/two.png'
import image_five from 'src/assets/intro/five.jpg'
import PageOne from './PageOne'
import PageTwo from './PageTwo'
import PageThree from './PageThree'
import PageFour from './PageFour'
import PageFive from './PageFive'

function Help() {
    const theme = useTheme()

    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [manualStep, setManualStep] = useState<number | undefined>(undefined)

    const handleOpen = (): void => {
        setIsDialogOpen(true)
    }

    const handleClose = (): void => {
        setIsDialogOpen(false)
    }

    const getContent = (step: number): JSX.Element => {
        switch (step) {
            case 0:
                return <PageOne setManualStep={setManualStep} />
            case 1:
                return <PageTwo />
            case 2:
                return <PageThree />
            case 3:
                return <PageFour />
            case 3:
                return <PageFour />
            case 4:
                return <PageFive />
            default:
                return <PageThree />
        }
    }

    return (
        <>
            <IconButton aria-label="notifications" onClick={handleOpen}>
                <HelpIcon color="action" />
            </IconButton>

            {isDialogOpen && (
                <StepperDialogMobile
                    open={isDialogOpen}
                    manualStep={manualStep}
                    setManualStep={setManualStep}
                    steps={[
                        {
                            label: 'Overview',
                            media: image_one,
                            content: getContent(0),
                        },
                        {
                            label: 'Demo Video',
                            content: getContent(1),
                            title: 'Demo Video',
                        },
                        {
                            label: 'Use Cases',
                            media: image_two,
                            content: getContent(2),
                        },
                        {
                            label: 'System Entities',
                            subtitle:
                                'Understanding the various entity relations will allow you to maximize the platform.',
                            content: getContent(3),
                            title: 'Ecosystem entities and relations',
                        },
                        {
                            label: 'FAQs',
                            subtitle:
                                'More specific FAQs are answered within the various user guides. For a selection of basic FAQs, please see the following.',
                            media: image_five,
                            title: 'Frequently Asked Questions',
                            content: getContent(4),
                        },
                    ]}
                    onClose={() => {
                        handleClose()
                    }}
                    onSubmit={() => console.log('el')}
                />
            )}
        </>
    )
}

export default Help
