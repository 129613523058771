import { baseAPI } from './baseAPI.service'
import {
    API_METHODS,
    API_ROUTES,
    ENDPOINT_URLS,
    Endpoint,
} from './config/apiTags'
import { AggregateP1ReadingsByBucketInput, DeleteSingleUtilityDataInput } from 'switch-shared/modules/crud/utility-data/utility-data.dto'
import { AggregateP1ReadingsByBucketResult } from 'switch-shared/modules/crud/utility-data/utility-data.dto'
import { AggregateP1ReadingsByMeterInput } from 'switch-shared/modules/crud/utility-data/utility-data.dto'
import { AggregateP1ReadingsByMeterResult } from 'switch-shared/modules/crud/utility-data/utility-data.dto'
import { AggregateP1SplitInput } from 'switch-shared/modules/crud/utility-data/utility-data.dto'
import { AggregateP1SplitOutput } from 'switch-shared/modules/crud/utility-data/utility-data.dto'
import { CreateMutationUtilityDataDto } from 'switch-shared/modules/crud/utility-data/utility-data.dto'
import { MutationBase } from 'switch-shared/modules/crud/utility-data/utility-data.dto'
import { ScaleUtilityDataMutation } from 'switch-shared/modules/crud/utility-data/utility-data.dto'
import { SearchUtilityDataDto } from 'switch-shared/modules/crud/utility-data/utility-data.dto'
import { SearchUtilityDataResultDto } from 'switch-shared/modules/crud/utility-data/utility-data.dto'
import { SuggestedPulseRateInput } from 'switch-shared/modules/crud/utility-data/utility-data.dto'
import { SuggestedPulseRateOutput } from 'switch-shared/modules/crud/utility-data/utility-data.dto'
import { UtilityData } from 'switch-shared/modules/crud/utility-data/utility-data.entity'

export const utilityDataAPI = baseAPI
    .enhanceEndpoints({
        addTagTypes: [Endpoint.UTILITY_DATA],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getUtilityData: builder.query<
                SearchUtilityDataResultDto,
                SearchUtilityDataDto
            >({
                query: (params) => {
                    return {
                        method: API_METHODS.GET,
                        url: API_ROUTES.utilityData.base,
                        params,
                    }
                },
                providesTags: [Endpoint.UTILITY_DATA]
            }),
            getUtilityDataByBucket: builder.query<
                AggregateP1ReadingsByBucketResult,
                AggregateP1ReadingsByBucketInput
            >({
                query: (params) => {
                    return {
                        method: API_METHODS.GET,
                        url: API_ROUTES.utilityData.aggregate,
                        params,
                    }
                },
            }),
            getAggregateP1Split: builder.query<
                AggregateP1SplitOutput,
                AggregateP1SplitInput
            >({
                query: (params) => {
                    return {
                        method: API_METHODS.GET,
                        url: API_ROUTES.utilityData.p1Split,
                        params,
                    }
                },
            }),
            getAggregateP1ByMeter: builder.query<
                AggregateP1ReadingsByMeterResult,
                AggregateP1ReadingsByMeterInput
            >({
                query: (params) => {
                    return {
                        method: API_METHODS.GET,
                        url: `${ENDPOINT_URLS.UTILITY_DATA}/aggregateP1ReadingsByMeter`,
                        params,
                    }
                },
            }),
            scaleUtilityData: builder.mutation<
                UtilityData[],
                ScaleUtilityDataMutation
            >({
                query: (data) => ({
                    url: `${ENDPOINT_URLS.UTILITY_DATA}/scaleUtilityData`,
                    method: API_METHODS.PATCH,
                    body: data,
                }),
            }),
            deleteUtilityData: builder.mutation<any, MutationBase>({
                query: (data) => ({
                    url: `${ENDPOINT_URLS.UTILITY_DATA}/deleteUtilityData`,
                    method: API_METHODS.PATCH,
                    body: data,
                }),
            }),
            createUtilityData: builder.mutation<
                UtilityData,
                CreateMutationUtilityDataDto
            >({
                query: (data) => ({
                    url: `${ENDPOINT_URLS.UTILITY_DATA}`,
                    method: API_METHODS.POST,
                    body: data,
                }),
            }),
            getSuggestedPulseRate: builder.query<
                SuggestedPulseRateOutput,
                SuggestedPulseRateInput
            >({
                query: (params) => ({
                    url: `${ENDPOINT_URLS.UTILITY_DATA}/suggestedPulseRate`,
                    method: API_METHODS.GET,
                    params,
                }),
            }),
            deleteSingleUtilityData: builder.mutation<void, DeleteSingleUtilityDataInput>({
                query: (body) => ({
                    url: `${ENDPOINT_URLS.UTILITY_DATA}/deleteSingleUtilityData`,
                    method: API_METHODS.DELETE,
                    body
                }),
                invalidatesTags: [Endpoint.UTILITY_DATA]
            })
        }),
    })

export const {
    useGetUtilityDataQuery,
    useLazyGetUtilityDataQuery,
    useGetUtilityDataByBucketQuery,
    useGetAggregateP1SplitQuery,
    useDeleteUtilityDataMutation,
    useScaleUtilityDataMutation,
    useGetAggregateP1ByMeterQuery,
    useCreateUtilityDataMutation,
    useLazyGetSuggestedPulseRateQuery,
    useDeleteSingleUtilityDataMutation,
} = utilityDataAPI
export default utilityDataAPI
