import { useEffect, useState } from 'react'
import { remoteConfig } from '../auth/clients/firebase/FirebaseAuthProvider'
import { fetchAndActivate, getValue } from 'firebase/remote-config'

const useRemoteConfig = (key: string) => {
    const [value, setValue] = useState<any>()

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                await fetchAndActivate(remoteConfig)
                const value = getValue(remoteConfig, key)
                const fetchedValue = JSON.parse(value.asString())
                setValue(fetchedValue)
            } catch (error) {
                console.error('Failed to fetch Remote Config', error)
            }
        }

        fetchConfig()
    }, [key])

    return value
}

export default useRemoteConfig
