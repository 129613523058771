import { CssBaseline } from '@mui/material'
import * as Sentry from '@sentry/react'
import { wrapUseRoutes } from '@sentry/react'
import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import router from './router/router'
import { getAbility } from './auth/utils/defineAbilityFor'
import { sentryConfig } from './config/sentry'
import { AbilityContext } from './context/canContext'
import DialogProvider from './context/dialogContext'
import { useAppDispatch, useAppSelector } from './hooks/hooks'
import Status500 from './pages/Fallbacks/Status/Status500/Status500'
import { ThemeProviderWrapper, selectTheme } from './features/appTheme'
import { NetworkState } from './constants/network-state'
import { fetchApplicationGroups } from './redux/application-groups/application-group.slice'
import { useEffect } from 'react'
import { fetchApplications } from './redux/applications/applicationSlice'
import { fetchAbilityRole } from './redux/ability-roles/abilityRoleSlice'
import { ALL_APP_ROUTES } from './constants/routing'
import { Helmet } from 'react-helmet-async'
import { getBrandFullName } from './utils/formatters'

Sentry.init(sentryConfig)
const useSentryRoutes = wrapUseRoutes(useRoutes)

function App() {
    const content = useSentryRoutes(router)
    const theme = useAppSelector(selectTheme)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const user = useAppSelector((state) => state.user)
    const abilityRole = useAppSelector((state) => state.abilityRole)
    const {
        applicationNetworkStatus,
        locallySelectedApplicationId,
        manualApplicationChange,
    } = useAppSelector((state) => state.application)
    const {
        applicationGroupNetworkStatus,
        locallySelectedApplicationGroupId,
        manualApplicationGroupChange,
    } = useAppSelector((state) => state.applicationGroup)
    const ability = abilityRole.abilityRoles.length
        ? getAbility(abilityRole.abilityRoles)
        : null //TODO: the user role should be added here

    /* START ASYNC LOADING */

    useEffect(() => {
        if (
            abilityRole.status === NetworkState.NOT_STARTED &&
            user.status === NetworkState.SUCCESS &&
            user.user?.uid
        ) {
            dispatch(fetchAbilityRole(user.user?.uid))
        }
        if (
            applicationGroupNetworkStatus.applicationGroups ===
                NetworkState.NOT_STARTED &&
            user.status === NetworkState.SUCCESS &&
            abilityRole.status === NetworkState.SUCCESS
        ) {
            dispatch(fetchApplicationGroups({}))
        }
        if (
            applicationNetworkStatus.applications ===
                NetworkState.NOT_STARTED &&
            applicationGroupNetworkStatus.applicationGroups ===
                NetworkState.SUCCESS &&
            user.status === NetworkState.SUCCESS
        ) {
            dispatch(fetchApplications(locallySelectedApplicationGroupId))
        }
    }, [
        dispatch,
        applicationGroupNetworkStatus.applicationGroups,
        applicationNetworkStatus.applications,
        abilityRole.status,
        user.status,
    ])

    /* END ASYNC LOADING */

    useEffect(() => {
        /* 
            IF MANUALLY CHANGING TO ALL APPLICATIONS THEN GO TO DASHBOARD
            ELSE GO TO ROOT OF THE PAGE YOU ARE CURRENTLY ON
        */

        if (manualApplicationChange || manualApplicationGroupChange) {
            if (
                locallySelectedApplicationId === 'All' &&
                !ALL_APP_ROUTES.includes(location.pathname.split('/')[1])
            ) {
                navigate('/home')
            } else if (locallySelectedApplicationId !== undefined) {
                const rootPath = location.pathname.split('/')[1]
                navigate(`/${rootPath}`)
            }
        }
    }, [locallySelectedApplicationId, applicationNetworkStatus])

    return (
        <ThemeProviderWrapper>
            <Helmet>
                <title>{getBrandFullName()} Console</title>
            </Helmet>
            <Sentry.ErrorBoundary
                fallback={
                    <Status500
                        resetErrorBoundary={() => window.location.reload()}
                    />
                }
            >
                <AbilityContext.Provider value={ability}>
                    <CssBaseline />
                    <DialogProvider>
                        <ToastContainer theme={'dark'} />
                        {content}
                    </DialogProvider>
                </AbilityContext.Provider>
            </Sentry.ErrorBoundary>
        </ThemeProviderWrapper>
    )
}

export default App
