const pages = {
    auth: {
        root: 'auth',
        login: {
            name: 'login',
            path: '/auth/login',
        },
        register: {
            name: 'register',
            path: '/auth/register',
        },
    },
    home: {
        name: 'home',
        path: '/home',
    },
    application: {
        name: 'applications',
        path: '/applications',
        create: {
            name: 'create-application',
            path: '/applications/create',
        },
        view: {
            name: 'view-application',
            path: '/applications/:id',
        },
        update: {
            name: 'update-application',
            path: '/applications/:id/update',
        },
        bulkSetup: {
            name: 'bulk-setup',
            path: '/applications/bulk-setup',
        },
    },
    billing: {
        name: 'billing',
        path: '/billing',
        create: {
            name: 'create-billing',
            path: '/billing/create',
        },
        view: {
            name: 'view-billing',
            path: '/billing/:id',
        },
        update: {
            name: 'update-billing',
            path: '/billing/:id/update',
        },
    },
    utility: {
        name: 'utilities',
        path: '/utilities',
        create: {
            name: 'create-utility',
            path: '/utilities/create',
        },
        view: {
            name: 'view-utility',
            path: '/utilities/:id',
        },
        update: {
            name: 'update-utility',
            path: '/utilities/:id/update',
        },
    },
    applicationGroup: {
        name: 'applicationGroups',
        path: '/applicationGroups',
        create: {
            name: 'create-application-group',
            path: '/applicationGroups/create',
        },
        view: {
            name: 'view-application-group',
            path: '/applicationGroups/:id',
        },
        update: {
            name: 'update-application-group',
            path: '/applicationGroups/:id/update',
        },
    },
    accounts: {
        name: 'accounts',
        path: '/accounts',
        create: {
            name: 'create-account',
            path: '/accounts/create',
        },
        view: {
            name: 'view-account',
            path: '/accounts/:id',
        },
        update: {
            name: 'update-account',
            path: '/accounts/:id/update',
        },
    },
    sensors: {
        name: 'sensors',
        path: '/sensors',
        view: {
            name: 'view-sensor',
            path: '/sensors/:id',
        },
    },
    meters: {
        name: 'meters',
        path: '/meters',
        create: {
            name: 'create-meter',
            path: '/meters/create',
        },
        view: {
            name: 'view-meter',
            path: '/meters/:id',
        },
        update: {
            name: 'update-meter',
            path: '/meters/:id/update',
        },
    },
    meterpoints: {
        name: 'meterPoints',
        path: '/meterPoints',
        create: {
            name: 'create-meterPoint',
            path: '/meterPoints/create',
        },
        view: {
            name: 'view-meterPoint',
            path: '/meterPoints/:id',
        },
        update: {
            name: 'update-meterPoint',
            path: '/meterPoints/:id/update',
        },
    },
    gateways: {
        name: 'gateways',
        path: '/gateways',
        createGateway: {
            name: 'create-gateway',
            path: '/gateways/create',
        },
        viewGateway: {
            name: 'view-gateway',
            path: '/gateways/:id',
        },
        updateGateway: {
            name: 'update-gateway',
            path: '/gateways/:id/update',
        },
    },
    unallocatedMeters: {
        name: 'unallocated-meters',
        path: '/unallocated-meters',
    },
    users: {
        name: 'users',
        path: '/users',
        create: {
            name: 'create-users',
            path: '/users/create',
        },
        view: {
            name: 'view-users',
            path: '/users/:id',
        },
        update: {
            name: 'update-users',
            path: '/users/:id/update',
        },
    },
    emailIntegrationLogs: {
        name: 'email-integration-logs',
        path: '/email-integration-logs',
    },
    tariffs: {
        name: 'tariffs',
        path: '/tariffs',
        create: {
            name: 'create-tariff',
            path: '/tariffs/create',
        },
        view: {
            name: 'view-tariff',
            path: '/tariffs/:id',
        },
        update: {
            name: 'update-tariff',
            path: '/tariffs/:id/update',
        },
    },
    recons: {
        name: 'recons',
        path: '/recons',
    },
    payments: {
        name: 'payments',
        path: '/payments',
    },
    reports: {
        name: 'reports',
        path: '/reports',
    },
    map: {
        name: 'map',
        path: '/map',
    },
    integrations: {
        name: 'integrations',
        path: '/integrations',
    },
    manualReadings: {
        name: 'manual-data',
        path: '/manual-data',
        whitelists: {
            name: 'email-integration-whitelists',
            path: '/manual-data/whitelists',
        },
    },
    financials: {
        name: 'financials',
        path: '/financials',
    },
    status: {
        root: 'status',
        unverified: {
            name: 'unverified',
            path: '/status/unverified',
        },
        statusComingSoon: {
            name: 'coming-soon',
            path: '/status/coming-soon',
        },
        statusMaintenance: {
            name: 'maintenance',
            path: '/status/maintenance',
        },
        status404: {
            name: '404',
            path: '/status/404',
        },
        status500: {
            name: '500',
            path: '/status/500',
        },
        statusSuccess: {
            name: 'success',
            path: '/status/success',
        },
        statusFailure: {
            name: 'failure',
            path: '/status/failure',
        },
        statusCancel: {
            name: 'cancel',
            path: '/status/cancel',
        },
    },
}

export default pages
