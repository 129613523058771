import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const ContentWrapper = styled(Box)(
  () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);

export default ContentWrapper;
