import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone'
import {
    Box,
    IconButton,
    Tooltip,
    Typography,
    styled,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { useContext } from 'react'
import UserInfo from 'src/components/UserInfo/UserInfo'
import appConfig from 'src/config/appConfig'
import { SidebarContext } from 'src/context/SidebarContext'
import { useAppSelector } from 'src/hooks/hooks'
import HeaderNotifications from './Buttons/Notifications'
import Help from './Buttons/Help'
import useRemoteConfig from 'src/hooks/useRemoteConfig'
import { useOrganisation } from 'src/hooks/useOrganisation'

const HeaderWrapper = styled(Box)(
    ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${theme.header.background};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-betwupdateSelectedApplicationIdeen;
        width: 100%;
        @media (min-width: ${
            theme.breakpoints.values[appConfig.breakMobileView]
        }px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
)

function Header() {
    const { sidebarToggle, toggleSidebar } = useContext(SidebarContext)

    const theme = useTheme()
    const super_whitelabelled_orgs: Array<String> = useRemoteConfig(
        'remove_switch_references'
    )
    const organisation = useOrganisation()

    const { locallySelectedApplicationId, applications } = useAppSelector(
        (state) => state.application
    )
    const { locallySelectedApplicationGroupId, applicationGroups } =
        useAppSelector((state) => state.applicationGroup)
    const group = applicationGroups.find(
        (ele) => ele._id === locallySelectedApplicationGroupId
    )
    const app = applications.find(
        (ele) => ele._id === locallySelectedApplicationId
    )

    return (
        <HeaderWrapper
            display="flex"
            alignItems="center"
            style={{ justifyContent: 'flex-start' }}
        >
            <Box
                display="flex"
                alignItems="center"
                width={'100%'}
                justifyContent={'center'}
            >
                <Box
                    component="span"
                    sx={{
                        display: {
                            [appConfig.breakMobileView]: 'none',
                            xs: 'inline-block',
                        },
                    }}
                >
                    <Tooltip arrow title="Toggle Menu">
                        <IconButton color="primary" onClick={toggleSidebar}>
                            {!sidebarToggle ? (
                                <MenuTwoToneIcon
                                    fontSize="small"
                                    sx={{
                                        color:
                                            theme.palette.mode === 'dark'
                                                ? 'white'
                                                : theme.colors.neutral.grey[40],
                                    }}
                                />
                            ) : (
                                <CloseTwoToneIcon fontSize="small" />
                            )}
                        </IconButton>
                    </Tooltip>
                </Box>

                <Typography color="primary">
                    {useMediaQuery(
                        theme.breakpoints.up(appConfig.breakMobileView)
                    ) && `${group?.name} |`}{' '}
                    {app?.name}
                </Typography>
                <Box
                    flexGrow={1}
                    display="flex"
                    component="span"
                    flexDirection={'row'}
                    flex={1}
                />
                <HeaderNotifications />
                {!super_whitelabelled_orgs?.includes(organisation) && <Help />}
                <UserInfo />
            </Box>
        </HeaderWrapper>
    )
}

export default Header
