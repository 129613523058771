import { Card } from '@mui/material'
import globalStyles from 'src/utils/constants/globalStyles'
import Logo from './Logo'

type Props = {
    children?: React.ReactNode
}
export const AuthWrapper = (props: Props) => {
    return (
        <Card
            sx={{
                ...globalStyles.card,
                textAlign: 'center',
                paddingTop: '42px',
            }}
            elevation={6}
        >
            <div style={{ marginTop: 4, marginBottom: 2, textAlign: 'center' }}>
                <Logo height={80} />
            </div>
            <div
                style={{
                    margin: 5,
                    width: '100%',
                    height: '100%',
                    padding: 5,
                    zIndex: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flexStart',
                    alignItems: 'center',
                    overflow: 'hidden',
                }}
            >
                {props.children}
            </div>
        </Card>
    )
}
