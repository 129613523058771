import { Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import YouTube, { YouTubeProps } from 'react-youtube'

const PageTwo = () => {
    /* Hooks & Use Effects */
    const theme = useTheme()

    const opts: YouTubeProps['opts'] = {
        height: 600,
        width: theme.breakpoints.values.lg - 50,
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    }
    /* States */

    /* API Calls */

    /* Functions & Functional Components */

    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        // access to player in all event handlers via event.target
        event.target.playVideo()
    }

    return (
        <>
            <Grid
                container
                flexDirection={'column'}
                sx={{
                    justifyContent: 'flex-start',
                }}
            >
                <YouTube
                    videoId="LquqWmW_AI0"
                    opts={opts}
                    onReady={onPlayerReady}
                />
            </Grid>
        </>
    )
}

export default PageTwo
