import { createApi } from '@reduxjs/toolkit/dist/query/react/index'
import { TAG_TYPES } from './config/apiTags'
import { baseQueryWithTokenExpirationCheck } from './config/query.config'

export const baseAPI = createApi({
    reducerPath: 'baseApi',
    baseQuery: baseQueryWithTokenExpirationCheck,
    tagTypes: [...TAG_TYPES],
    endpoints: () => ({}),
})

// Used to add custom toasts, see redux/middleware.ts:
export const apiToasts: {
    [endpointName: string]: {
        pending?: string | 0
        fulfilled?: string | 0
    }
} = {}
export function setToast(
    endpointName: string,
    toast: (typeof apiToasts)[string]
) {
    apiToasts[endpointName] = toast
}
