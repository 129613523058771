import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import { getBrandFullName } from 'src/utils/formatters'

const useCases = [
    {
        primary: `\u2022 Utility Monitoring`,
        secondary:
            'View consumption and statistical breakdown of usage in addition to receiving custom alerts.',
    },
    {
        primary: `\u2022 Hardware Management`,
        secondary: 'Install & monitor meters, communication gateways and more.',
    },
    {
        primary: `\u2022 Billing & Invoicing`,
        secondary:
            'Charge tenants for utility consumption via the pre-paid wallet system or post-paid invoices.',
    },
    {
        primary: `\u2022 Reporting`,
        secondary: 'Generate detailed reports for a variety of metrics.',
    },
    {
        primary: `\u2022 User Management`,
        secondary:
            'Invite users to applications, control their permissions and limit their scope.',
    },
]

const PageTwo = () => {
    /* Hooks & Use Effects */

    /* States */

    /* API Calls */

    /* Functions & Functional Components */

    return (
        <>
            <Grid
                container
                flexDirection={'column'}
                sx={{
                    justifyContent: 'flex-start',
                }}
            >
                <Typography variant="h3" sx={{ mb: 2 }}>
                    The {getBrandFullName()} admin console primarily serves the
                    following 5 use-cases:
                </Typography>
                <List dense={true}>
                    {useCases.map((ele, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={ele.primary}
                                primaryTypographyProps={{ variant: 'h4' }}
                                secondary={ele.secondary}
                            />
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </>
    )
}

export default PageTwo
