import { BucketSize } from "../enums/utility-data";
import dayjs from "dayjs";

export const capitalize = (value: string) => {
  return value.slice(0, 1).toUpperCase().concat(value.slice(1));
};

export const formatNumber = (value: number, decimals?: number) => {
  return Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: decimals ?? 3,
    minimumFractionDigits: 0,
  }).format(value);
};

export const roundUsage = (num: number, decimals: number) => {
  const factorOfTen = Math.pow(10, decimals);
  return Math.round(num * factorOfTen) / factorOfTen;
};

export const formatDateByBucket = (date: Date, bucketSize?: BucketSize) => {
  const day = dayjs(date);
  switch (bucketSize) {
    case BucketSize.DAILY:
      return day.format("D MMM");
    case BucketSize.HOURLY:
      return day.format("HH:mm");
    case BucketSize.QUARTER_HOURLY:
      return day.format("HH:mm");
    case BucketSize.MINUTELY:
      return day.format("HH:mm");
    default:
      return day.format("D MMM YYYY, HH:mm:ss");
  }
};

export const isNumber = (input: string): boolean => {
  const regex = /^[0-9.]*$/;
  if (regex.test(input) && (input.match(/\./g) || []).length < 2) {
    return true;
  }
  return false;
};
