import {
    AccountBalance,
    Apps,
    Article,
    ControlPointDuplicate,
    DataObject,
    MonetizationOn,
    Notifications,
    PaidOutlined,
    Payments,
    People,
    RequestQuote,
    SettingsInputAntenna,
    Speed,
    WbSunny,
} from '@mui/icons-material'

export enum ROLES {
    SUPER_ADMIN = 'super-admin',
    GROUP_ADMIN = 'group-admin',
    READ_ONLY = 'read-only',
    CUSTOM = 'custom',
    MOBILE = 'mobile',
}

export enum USER_TYPE {
    ADMIN = 'admin',
    MOBILE = 'mobile',
}

export const USER_TYPES = [
    { value: USER_TYPE.ADMIN, label: 'Admin' },
    { value: USER_TYPE.MOBILE, label: 'Mobile App' },
]

export const ROLE_TYPES = [
    { value: ROLES.SUPER_ADMIN, label: 'Super Admin', ranking: 0 },
    { value: ROLES.GROUP_ADMIN, label: 'Group Admin', ranking: 1 },
    { value: ROLES.READ_ONLY, label: 'Read only', ranking: 2 },
    { value: ROLES.CUSTOM, label: 'Custom', ranking: 3 },
]

export type AbilityRole = {
    role: ROLES
    userId?: string
    apiKeyId?: string
    scope: Array<ScopeItemClass>
    abilities: Array<AbilityObjectClass>
}

export type AbilityObjectClass = {
    action: string
    subject: string
    conditions?: any
    inverted?: boolean
}

export class ScopeItemClass {
    applicationGroupId?: string
    applicationIds?: Array<string>
    allApplications?: boolean
    allApplicationGroups?: boolean
}

export const ROLE_SUBJECTS_MAPPING: Record<string, string> = {
    Applications: 'Applications',
    Users: 'Users',
    ApplicationUtilities: 'Utilities',
    MeterPoints: 'Meter Points',
    Meters: 'Meters',
    UtilityData: 'Utility Data',
    Gateways: 'Gateways',
    Accounts: 'Accounts',
    Tariffs: 'Tariffs',
    Reports: 'Reports',
    Alerts: 'Alerts',
    Transactions: 'Transactions',
    Payments: 'Payments',
    BillingRuns: 'Billing',
}

export const Role_Subjects: Omit<any, 'onChange'>[] = [
    { title: 'Applications', subject: 'Applications', icon: <Apps /> },
    {
        title: 'Users',
        subject: 'Users',
        icon: <People />,
        description: 'Determines whether a user can manage other users',
    },
    { title: 'Utilities', subject: 'ApplicationUtilities', icon: <WbSunny /> },
    {
        title: 'Meter Points',
        subject: 'MeterPoints',
        icon: <ControlPointDuplicate />,
    },
    { title: 'Meters', subject: 'Meters', icon: <Speed /> },
    {
        title: 'Utility Data',
        subject: 'UtilityData',
        icon: <DataObject />,
        description:
            'Determines whether a user can create and delete raw utility data',
    },
    { title: 'Gateways', subject: 'Gateways', icon: <SettingsInputAntenna /> },
    { title: 'Accounts', subject: 'Accounts', icon: <AccountBalance /> },
    { title: 'Tariffs', subject: 'Tariffs', icon: <RequestQuote /> },
    {
        title: 'Reports',
        subject: 'Reports',
        icon: <Article />,
        description:
            'Determines whether a user can create, view and manage reports',
    },
    {
        title: 'Alerts',
        subject: 'Alerts',
        icon: <Notifications />,
    },
    { title: 'Transactions', subject: 'Transactions', icon: <PaidOutlined /> },
    { title: 'Payments', subject: 'Payments', icon: <Payments /> },
    {
        title: 'Billing',
        subject: 'BillingRuns',
        icon: <MonetizationOn />,
        description: `Determines whether a user can manage an application's billing`,
    },
]

export enum ROLE_ACTIONS {
    MANAGE = 'manage',
    READ = 'read',
    UPDATE = 'update',
    DELETE = 'delete',
    CREATE = 'create',
}

export const Action_Options = [
    { action: ROLE_ACTIONS.MANAGE, title: 'Manage' },
    { action: ROLE_ACTIONS.READ, title: 'Read' },
]
