import React, { ReactNode } from 'react'
import { useOrganisation } from 'src/hooks/useOrganisation'
import useRemoteConfig from 'src/hooks/useRemoteConfig'

interface Props {
    flag: string
    children: ReactNode
}

const FBRemoteConfigWrapper: React.FC<Props> = ({ flag, children }) => {
    //TODO: Make this super generic for any remote flag
    const super_whitelabelled_orgs: Array<String> = useRemoteConfig(flag)
    const organisation = useOrganisation()

    if (!super_whitelabelled_orgs) {
        return <></>
    }

    if (!super_whitelabelled_orgs?.includes(organisation)) {
        return <>{children}</>
    }

    return <></>
}

export default FBRemoteConfigWrapper
