/* eslint-disable consistent-return */
import axios, { AxiosRequestHeaders } from 'axios'
import { getAuth } from 'firebase/auth'
axios.defaults.headers.common['request-source'] = 'admin-console'

export interface ResultWithValueAndHttpCode<T> {
    isSuccess: boolean
    value: T | null
    errorMessage: string
    httpCode: string | number
}

export interface ResultWithHttpCode {
    isSuccess: boolean
    errorMessage: string
    httpCode: string | number
}

class BaseApiService {
    private readonly _baseUrl!: string

    constructor(newBaseUrl?: string) {
        if (newBaseUrl !== null && newBaseUrl !== undefined) {
            this._baseUrl = newBaseUrl
            axios.interceptors.request.use(
                //@ts-ignore
                async (request) => {
                    if (request.headers) {
                        const idToken: string | undefined =
                            await getAuth()?.currentUser?.getIdToken?.()
                        if (idToken) {
                            request.headers.Authorization = `Bearer ${idToken}`
                        }
                        return request
                    }
                    return ''
                },
                async (err) => await Promise.reject(err)
            )
        }
    }

    protected async get<T>(
        url: string
        // @ts-expect-error
    ): Promise<ResultWithValueAndHttpCode<T>> {
        try {
            const result = await axios.get<T>(`${this._baseUrl}/${url}`)
            return {
                isSuccess: true,
                value: result.data,
                errorMessage: '',
                httpCode: result.status,
            }
        } catch (ex: unknown) {
            if (ex instanceof Error) {
                console.log(
                    `baseApiService (${this._baseUrl}/${url}): `,
                    ex.message
                )
                return {
                    isSuccess: false,
                    value: null,
                    errorMessage: ex.message,
                    httpCode: 500, // get help with this
                }
            }
        }
    }

    protected async getWithParams<T>(
        url: string,
        params: Object
        // @ts-expect-error
    ): Promise<ResultWithValueAndHttpCode<T>> {
        try {
            const result = await axios.get<T>(`${this._baseUrl}/${url}`, {
                params,
            })
            return {
                isSuccess: true,
                value: result.data,
                errorMessage: '',
                httpCode: result.status,
            }
        } catch (ex: unknown) {
            if (ex instanceof Error) {
                console.log('baseApiService: ', ex.message)
                return {
                    isSuccess: false,
                    value: null,
                    errorMessage: ex.message,
                    httpCode: 500, // get help with this
                }
            }
        }
    }

    protected async deleteWithParams<T>(
        url: string,
        params: Object
        // @ts-expect-error
    ): Promise<ResultWithValueAndHttpCode<T>> {
        try {
            const result = await axios.delete<T>(`${this._baseUrl}/${url}`, {
                params,
            })
            return {
                isSuccess: true,
                value: result.data,
                errorMessage: '',
                httpCode: result.status,
            }
        } catch (ex) {
            if (ex instanceof Error) {
                console.log(
                    `baseApiService (${this._baseUrl}/${url}): `,
                    ex.message
                )
                return {
                    isSuccess: false,
                    value: null,
                    errorMessage: ex.message,
                    httpCode: ex.name, // get help with this
                }
            }
        }
    }

    protected async post<T>(
        url: string,
        data: any,
        manipulateHeaders?: (headers: any) => void
        // @ts-expect-error
    ): Promise<ResultWithValueAndHttpCode<T>> {
        const realUrl = `${this._baseUrl}/${url}`
        console.log(`baseApiService (${this._baseUrl}/${url}): `)
        try {
            const result = await axios.post<T>(realUrl, data)
            if (manipulateHeaders != null) manipulateHeaders(result.headers)

            return {
                isSuccess: true,
                value: result.data,
                errorMessage: '',
                httpCode: result.status,
            }
        } catch (ex) {
            if (ex instanceof Error) {
                console.log(
                    `baseApiService (${this._baseUrl}/${url}): `,
                    ex.message
                )
                return {
                    isSuccess: false,
                    value: null,
                    errorMessage: ex.message,
                    httpCode: ex.name, // get help with this
                }
            }
        }
    }

    protected async delete(
        url: string,
        manipulateHeaders?: (headers: any) => void,
        data?: any
        // @ts-expect-error
    ): Promise<ResultWithHttpCode> {
        try {
            const result = await axios.delete(`${this._baseUrl}/${url}`, {
                data,
            })
            if (manipulateHeaders != null) manipulateHeaders(result.headers)
            return {
                isSuccess: true,
                errorMessage: '',
                httpCode: result.status,
            }
        } catch (ex) {
            if (ex instanceof Error) {
                return {
                    isSuccess: false,
                    errorMessage: ex.message,
                    httpCode: ex.name, // get help with this
                }
            }
        }
    }

    protected async put<T>(
        url: string,
        data: any,
        manipulateHeaders?: (headers: any) => void
        // @ts-expect-error
    ): Promise<ResultWithValueAndHttpCode<T>> {
        const realUrl = `${this._baseUrl}/${url}`
        try {
            const result = await axios.put<T>(realUrl, data)
            if (manipulateHeaders != null) manipulateHeaders(result.headers)

            return {
                isSuccess: true,
                value: result.data,
                errorMessage: '',
                httpCode: result.status,
            }
        } catch (ex) {
            if (ex instanceof Error) {
                console.log(
                    `baseApiService (${this._baseUrl}/${url}): `,
                    ex.message
                )
                return {
                    isSuccess: false,
                    value: null,
                    errorMessage: ex.message,
                    httpCode: ex.name, // get help with this
                }
            }
        }
    }

    protected async patch<T>(
        url: string,
        data?: any,
        customHeaders?: AxiosRequestHeaders | undefined
        // @ts-expect-error
    ): Promise<ResultWithValueAndHttpCode<T>> {
        const realUrl = `${this._baseUrl}/${url}`
        try {
            const result = await axios.patch<T>(realUrl, data, {
                headers: customHeaders,
            })
            return {
                isSuccess: true,
                value: result.data,
                errorMessage: '',
                httpCode: result.status,
            }
        } catch (ex) {
            if (ex instanceof Error) {
                console.log(
                    `baseApiService (${this._baseUrl}/${url}): `,
                    ex.message
                )
                return {
                    isSuccess: false,
                    value: null,
                    errorMessage: ex.message,
                    httpCode: ex.name, // get help with this
                }
            }
        }
    }
}

export default BaseApiService
