import pages from 'src/router/routes'

export const ALL_APP_ROUTES = [
    pages.home.name,
    pages.application.name,
    pages.applicationGroup.name,
    pages.unallocatedMeters.name,
    pages.users.name,
    pages.reports.name,
]
