export enum BucketSize {
    HOURLY = 60,
    DAILY = 1440,
    QUARTER_HOURLY = 15,
    MINUTELY = 5,
}

export enum DateOption {
    THIS_WEEK = 'this_week',
    PAST_MONTH = 'past_30_days',
    PAST_DAY = 'past_24_hours',
    CUSTOM = 'custom',
}