export enum UtilityType {
  ELECTRICITY = 'electricity',
  WATER = 'water',
  GAS = 'gas',
  FIXED_FEE = 'fixedFee',
}

export function parseUtilityType(str: string): UtilityType {
  for (const utilityType of Object.values(UtilityType)) {
    if (utilityType === str) return utilityType;
  }

  throw new Error(`Invalid utility type: ${str}`);
}

export function parseEnumValue<T>(str: string, enumObject: T): T[keyof T] {
  for (const enumValue of Object.values(enumObject)) {
    if (enumValue === str) return enumValue as T[keyof T];
  }

  throw new Error(`Invalid enum value: ${str}`);
}

/**
 * Returns the symbol for the given utility's base units.
 */
export function getUnitSymbol(type: UtilityType): string {
  switch(type) {
    case UtilityType.ELECTRICITY:
      return 'Wh';

    case UtilityType.WATER:
      return 'l';

    case UtilityType.GAS:
      return 'cf';

    default:
      throw new Error(`Utility type ${type} has no associated units.`);
  }
}

/**
 * Returns the name of the given utility's base units.
 */
export function getUnitName(type: UtilityType): string {
  switch(type) {
    case UtilityType.ELECTRICITY:
      return 'Watt-hour';

    case UtilityType.WATER:
      return 'litre';

    case UtilityType.GAS:
      return 'cubic-feet';

    default:
      throw new Error(`Utility type ${type} has no associated units.`);
  }
}

/**
 * Returns the symbol for the given utility's bulk units.
 */
export function getBulkUnitSymbol(type: UtilityType): string {
  switch(type) {
    case UtilityType.ELECTRICITY:
      return 'kWh';

    case UtilityType.WATER:
      return 'kl';

    case UtilityType.GAS:
      return 'kcf';

    default:
      throw new Error(`Utility type ${type} has no associated units.`);
  }
}

/**
 * Converts from base units to bulk units in the given utility. This is pretty
 * much always divide-by-thousand, but we might support other stuff one day.
 */
export function toBulkUnits(
  type: UtilityType,
  units: number,
  costPerUnit?: number,
): {
  bulkUnits: number;
  costPerBulkUnit?: number;
} {
  switch(type) {
    case UtilityType.ELECTRICITY:
    case UtilityType.WATER:
    case UtilityType.GAS:
      return {
        bulkUnits: units / 1000.0,
        ...(costPerUnit != null && { costPerBulkUnit: costPerUnit * 1000.0 }),
      };

    default:
      throw new Error(`Utility type ${type} has no associated units.`);
  }
}
