import { Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import img from 'src/assets/intro/three.png'

const PageThree = () => {
    /* Hooks & Use Effects */
    const theme = useTheme()

    /* States */

    /* API Calls */

    /* Functions & Functional Components */

    return (
        <>
            <Grid
                container
                flexDirection={'column'}
                sx={{
                    justifyContent: 'flex-start',
                }}
            >
                <img src={img} />
            </Grid>
        </>
    )
}

export default PageThree
