export enum BILLING_TYPE {
    PREPAID = 'prepaid',
    POSTPAID = 'postpaid',
    ADHOC = 'adhoc',
}

export const BillingTypesArray = [
    { value: BILLING_TYPE.PREPAID, label: 'Prepaid' },
    { value: BILLING_TYPE.POSTPAID, label: 'Postpaid' },
    { value: BILLING_TYPE.ADHOC, label: 'Ad-hoc' },
]

export const MeterPointDetectionOptions = [
    {
        _id: 'true',
        name: 'Automated',
    },
    {
        _id: 'false',
        name: 'Manual',
    },
]

export const EnabledDisabledOptions = [
    {
        _id: 'true',
        name: 'Enabled',
    },
    {
        _id: 'false',
        name: 'Disabled',
    },
]

export interface BillingSummary {
    utilityType: string
    bulkUsage: number
    bulkAmount: number
    submeterUsage: number
    submeterAmount: number
    deltaUsage: number
    deltaAmount: number
}
