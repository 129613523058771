import PropTypes from 'prop-types'
import { FC, ReactNode } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'

import { Box, useTheme } from '@mui/material'

interface ScrollbarProps {
    className?: string
    children?: ReactNode
    style?: any
    autoHide?: boolean
}

const Scrollbar: FC<ScrollbarProps> = ({
    className,
    children,
    autoHide,
    ...rest
}) => {
    const theme = useTheme()

    return (
        <Scrollbars
            autoHide={autoHide}
            autoHideTimeout={1000}
            // autoHeight
            autoHeightMax={1000}
            renderThumbVertical={() => {
                return (
                    <Box
                        sx={{
                            width: 5,
                            background: `${theme.colors.neutral.black[20]}`,
                            borderRadius: `${theme.general.borderRadiusLg}`,
                            transition: `${theme.transitions.create([
                                'background',
                            ])}`,

                            '&:hover': {
                                background: `${theme.colors.neutral.black[40]}`,
                            },
                        }}
                    />
                )
            }}
            {...rest}
        >
            {children}
        </Scrollbars>
    )
}

Scrollbar.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

export default Scrollbar
