import { ENDPOINT_URLS } from './config/apiTags'
import BaseApiService, { ResultWithValueAndHttpCode } from './rest-base.service'

class AbilityRoleApiService extends BaseApiService {
    constructor() {
        super(process.env.REACT_APP_BASE_API_URL?.slice(0, -1))
    }

    public async getAbilityRole(params: {
        userId?: string
    }): Promise<ResultWithValueAndHttpCode<any> | undefined> {
        const apiResult: ResultWithValueAndHttpCode<any> | undefined =
            await this.get<any>(
                `${ENDPOINT_URLS.ABILITY_ROLE}/${params.userId}`
            )
        if (apiResult !== undefined) {
            if (!apiResult.isSuccess) {
                return await Promise.reject(
                    new Error(
                        JSON.stringify({
                            isSuccess: false,
                            value: null,
                            errorMessage: apiResult.errorMessage,
                            httpCode: apiResult.httpCode,
                        })
                    )
                )
            }
            return {
                isSuccess: true,
                value: apiResult.value,
                errorMessage: '',
                httpCode: apiResult.httpCode,
            }
        }
    }
}

export default AbilityRoleApiService
