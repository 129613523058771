import React from 'react'

import { Theme } from '@mui/material'
//@ts-ignore
import { LightTheme } from 'lightTheme'
//@ts-ignore
import { DarkTheme } from 'darkTheme'

const themeMap: { [key: string]: Theme } = {
    LightTheme,
    DarkTheme,
}

export function themeCreator(theme: string): Theme {
    return themeMap[theme]
}

declare module '@mui/material/styles' {
    interface Theme {
        colors: {
            neutral: {
                white: {
                    20: string
                    40: string
                    60: string
                    80: string
                    100: string
                }
                black: {
                    20: string
                    40: string
                    60: string
                    80: string
                    100: string
                }
                grey: {
                    20: string
                    40: string
                    60: string
                    80: string
                    100: string
                }
            }
            gradients: {
                blue1: string
                blue2: string
                blue3: string
                blue4: string
                blue5: string
                orange1: string
                orange2: string
                orange3: string
                purple1: string
                purple3: string
                pink1: string
                pink2: string
                green1: string
                green2: string
                black1: string
                black2: string
            }
            primary: {
                lighter: string
                light: string
                main: string
                dark: string
            }
            alpha: {
                white: {
                    5: string
                    10: string
                    30: string
                    50: string
                    70: string
                    100: string
                }
                trueWhite: {
                    5: string
                    10: string
                    30: string
                    50: string
                    70: string
                    100: string
                }
                black: {
                    5: string
                    10: string
                    30: string
                    50: string
                    70: string
                    100: string
                }
            }
        }

        // custom component styling
        general: {
            reactFrameworkColor: React.CSSProperties['color']
            borderRadiusSm: string
            borderRadius: string
            borderRadiusLg: string
            borderRadiusXl: string
        }
        sidebar: {
            background: React.CSSProperties['color']
            boxShadow: React.CSSProperties['color']
            width: string
            textColor: React.CSSProperties['color']
            dividerBg: React.CSSProperties['color']
            menuItemColor: React.CSSProperties['color']
            menuItemColorActive: React.CSSProperties['color']
            menuItemBg: React.CSSProperties['color']
            menuItemBgActive: React.CSSProperties['color']
            menuItemIconColor: React.CSSProperties['color']
            menuItemIconColorActive: React.CSSProperties['color']
            menuItemHeadingColor: React.CSSProperties['color']
        }
        header: {
            height: string
            background: React.CSSProperties['color']
            boxShadow: React.CSSProperties['color']
            textColor: React.CSSProperties['color']
        }
    }

    interface ThemeOptions {
        colors: Theme['colors']
        general: Theme['general']
        sidebar: Theme['sidebar']
        header: Theme['header']
    }
}
