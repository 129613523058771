import VerifiedIcon from "@mui/icons-material/Verified";
import { Avatar, Badge, Tooltip } from "@mui/material";

interface UserAvatarProps {
  user?: any;
}

const UserAvatar = ({ user }: UserAvatarProps) => {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      badgeContent={
        <>
          <Tooltip
            arrow
            placement="top"
            title={user?.emailVerified ? "Verified" : ""}
          >
            {user?.emailVerified && <VerifiedIcon fontSize="small" />}
          </Tooltip>
        </>
      }
    >
      <Avatar
        src={user?.photoURL ? user.photoURL : ""}
        imgProps={{
          sx: {
            referrerpolicy: "no-referrer",
            maskImage:
              "radial-gradient(circle, white 66%, rgba(255, 254, 255, 0.5) 10%)",
          },
        }}
        sx={{
          alt: user?.email,
        }}
      />
    </Badge>
  );
};

export default UserAvatar;
