import {
    Middleware,
    MiddlewareAPI,
    isRejectedWithValue,
} from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { apiToasts } from 'src/services/switch-rest-api/baseAPI.service'
import { formatReduxRTKMutation } from 'src/utils/formatters'

export const rtkQueryMiddleware: Middleware =
    (api?: MiddlewareAPI) => (next) => async (action) => {
        if (isRejectedWithValue(action)) {
            const status = action.payload.status
            const message = action?.payload?.data?.message
            toast.dismiss()
            if (status === 401) {
                toast.error(
                    message ??
                        'Your session has expired. Redirecting to the login page.',
                    {
                        autoClose: 10000,
                    }
                )
            } else if (status === 403) {
                toast.error('User is not authorised to perform this action.')
            } else if (status === 500) {
                toast.error(message ?? 'Internal server error.')
            } else if (status === 404) {
                toast.error(message ?? 'Resource not found.')
            } else if (status === 400) {
                toast.error(
                    action?.payload?.data?.errorMessage ??
                        'An error has occurred',
                    {
                        autoClose: 10000,
                    }
                )
                // add more if statements for other explicit status code handling
            } else {
                toast.error(
                    action?.payload?.data?.errorMessage ??
                        'An error has occurred and there was no message.',
                    { autoClose: 10000 }
                )
            }
        } else if (action?.meta?.arg?.endpointName) {
            const endpointName = action?.meta?.arg?.endpointName
            const toastMeta = apiToasts[endpointName]

            if (toastMeta != null || action?.meta?.arg?.type === 'mutation') {
                if (action.type.endsWith('/fulfilled')) {
                    toast.dismiss()
                    if (toastMeta?.fulfilled != null) {
                        toastMeta.fulfilled !== 0 &&
                            toast.success(toastMeta.fulfilled, {
                                hideProgressBar: true,
                            })
                    } else {
                        toast.success(
                            action?.payload?.data?.message ?? 'Success!',
                            { hideProgressBar: true }
                        )
                    }
                } else if (action.type.endsWith('/pending')) {
                    if (toastMeta?.pending != null) {
                        toastMeta.pending !== 0 &&
                            toast.info(toastMeta.pending, {
                                hideProgressBar: true,
                            })
                    } else {
                        toast.info(
                            `${formatReduxRTKMutation(
                                action?.meta?.arg?.endpointName
                            )}...`,
                            {
                                hideProgressBar: true,
                            }
                        )
                    }
                }
            }
        }
        return next(action)
    }
