import {
    Box,
    Divider,
    Drawer,
    Grid,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    alpha,
    lighten,
    styled,
    useTheme,
} from '@mui/material'
import { useContext } from 'react'
import { NavLink as RouterLink, useNavigate } from 'react-router-dom'
import Scrollbar from 'switch-fe-shared/components/Scrollbar/Scrollbar'
import appConfig from 'src/config/appConfig'
import { SidebarContext } from 'src/context/SidebarContext'
import Logo, { LogoVariant } from 'src/pages/Login/components/Logo'
import SidebarMenu from './SidebarMenu'
import { Email, Language, Phone } from '@mui/icons-material'
// @ts-expect-error
import details from 'details.json'
import pages from 'src/router/routes'
import { useOrganisation } from 'src/hooks/useOrganisation'
import { ORGANISATION } from 'src/constants/general'

const SidebarWrapper = styled(Box)(
    ({ theme }) => `
    width: ${theme.sidebar.width};
    min-width: ${theme.sidebar.width};
    color: ${theme.colors.alpha.trueWhite[70]};
    position: relative;
    z-index: 7;
    height: 100%;
    padding-bottom: 8px;
`
)

function Sidebar() {
    const { sidebarToggle, toggleSidebar } = useContext(SidebarContext)
    const closeSidebar = () => toggleSidebar()
    const theme = useTheme()
    const environment = useOrganisation()
    const navigate = useNavigate()

    return (
        <>
            <SidebarWrapper
                sx={{
                    zIndex: 0,
                    display: {
                        xs: 'none',
                        [appConfig.breakMobileView]: 'inline-block',
                    },
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    background:
                        theme.palette.mode === 'dark'
                            ? alpha(
                                  lighten(
                                      theme.header.background ?? '#111633',
                                      0.1
                                  ),
                                  0.5
                              )
                            : theme.header.background,
                    boxShadow:
                        theme.palette.mode === 'dark'
                            ? theme.sidebar.boxShadow
                            : 'none',
                }}
            >
                <Grid container flexDirection={'column'} height={'100%'}>
                    <Grid item marginTop={3}>
                        <Tooltip
                            enterDelay={700}
                            title={
                                <Grid container flexDirection={'column'}>
                                    {details.website && (
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                component={RouterLink}
                                                to={`${details.website}`}
                                                target="_blank"
                                            >
                                                <ListItemIcon>
                                                    <Language
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={details.website}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    )}

                                    {details.email && (
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                component={RouterLink}
                                                to={`mailto:${details.email}`}
                                            >
                                                <ListItemIcon>
                                                    <Email
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={details.email}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    )}

                                    {details.number && (
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                component={RouterLink}
                                                to={`tel:${details.number}`}
                                            >
                                                <ListItemIcon>
                                                    <Phone
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={details.number}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                </Grid>
                            }
                        >
                            <Box
                                onClick={() => navigate(pages.home.path)}
                                mx={2}
                                mb={1}
                                sx={{
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                            >
                                <Logo
                                    height={40}
                                    variant={LogoVariant.PRIMARY}
                                    sxProps={
                                        environment === ORGANISATION.MANUFLO
                                            ? { paddingLeft: 0.5 }
                                            : {}
                                    }
                                />
                            </Box>
                        </Tooltip>
                    </Grid>
                    <Grid item flex={1}>
                        <SidebarMenu />
                    </Grid>
                </Grid>
            </SidebarWrapper>
            <Drawer
                sx={{
                    boxShadow: `${theme.sidebar.boxShadow}`,
                }}
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={sidebarToggle}
                onClose={closeSidebar}
                variant="temporary"
                elevation={9}
            >
                <SidebarWrapper
                    sx={{
                        background:
                            theme.palette.mode === 'dark'
                                ? alpha(
                                      lighten(
                                          theme.header.background ?? '#111633',
                                          0.1
                                      ),
                                      0.5
                                  )
                                : theme.header.background,
                        boxShadow:
                            theme.palette.mode === 'dark'
                                ? theme.sidebar.boxShadow
                                : 'none',
                    }}
                >
                    <Scrollbar>
                        <Box mt={3}>
                            <Box
                                mx={2}
                                mb={1}
                                sx={{
                                    width: 52,
                                }}
                            >
                                <Tooltip
                                    title={
                                        <Grid
                                            container
                                            flexDirection={'column'}
                                        >
                                            {details.website && (
                                                <ListItem disablePadding>
                                                    <ListItemButton
                                                        component={RouterLink}
                                                        to={`${details.website}`}
                                                        target="_blank"
                                                    >
                                                        <ListItemIcon>
                                                            <Language
                                                                style={{
                                                                    color: 'white',
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                details.website
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            )}

                                            {details.email && (
                                                <ListItem disablePadding>
                                                    <ListItemButton
                                                        component={RouterLink}
                                                        to={`mailto:${details.email}`}
                                                    >
                                                        <ListItemIcon>
                                                            <Email
                                                                style={{
                                                                    color: 'white',
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                details.email
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            )}
                                            {details.number && (
                                                <ListItem disablePadding>
                                                    <ListItemButton
                                                        component={RouterLink}
                                                        to={`tel:${details.number}`}
                                                    >
                                                        <ListItemIcon>
                                                            <Phone
                                                                style={{
                                                                    color: 'white',
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                details.number
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            )}
                                        </Grid>
                                    }
                                >
                                    <Box
                                        onClick={() =>
                                            navigate(pages.home.path)
                                        }
                                        mx={2}
                                        mb={1}
                                        sx={{
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <Logo
                                            height={40}
                                            variant={LogoVariant.PRIMARY}
                                        />
                                    </Box>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Divider
                            sx={{
                                mt: theme.spacing(3),
                                mx: theme.spacing(2),
                                background: theme.colors.alpha.trueWhite[10],
                            }}
                        />
                        <SidebarMenu />
                    </Scrollbar>
                </SidebarWrapper>
            </Drawer>
        </>
    )
}

export default Sidebar
