import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import { Button, Tooltip } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks'
import { selectTheme, toggleTheme } from '../../store/theme/themeSlice'
import { themeNames } from '../../store/theme/themeSlice.contracts'

const ThemeSwitch = () => {
    const theme = useAppSelector(selectTheme)
    const dispatch = useAppDispatch()

    return (
        <Button onClick={() => dispatch(toggleTheme({}))}>
            <Tooltip
                arrow
                title={theme === themeNames.light ? 'Light Mode' : 'Dark Mode'}
            >
                {theme === themeNames.light ? (
                    <LightModeIcon />
                ) : (
                    <DarkModeIcon />
                )}
            </Tooltip>
        </Button>
    )
}

export default ThemeSwitch
