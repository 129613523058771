import dayjs from 'dayjs'
import numeral from 'numeral'
import { DateRangeOptions, DateRangeType, UtilityType } from 'src/constants'
import { ORGANISATION } from 'src/constants/general'
import { IoTMeterState } from 'switch-shared/modules/crud/meter/meter.dto'

export const getBrandFullName = () => {
    switch (process.env.REACT_APP_BRAND) {
        case ORGANISATION.SWITCH:
            return 'Switch Energy'
        case ORGANISATION.METER_MATE:
            return 'Meter Mate'
        case ORGANISATION.HYDRO_WELLNESS:
            return 'Hydro Wellness'
        case ORGANISATION.MANUFLO:
            return 'Manuflo'
        case ORGANISATION.JOAT:
            return 'JOAT'
        default:
            return 'Switch Energy'
    }
}

export const getEllipsisTxt = (str: string, n = 6) => {
    if (str) {
        return `${str.substr(0, n)}...${str.substr(str.length - n, str.length)}`
    }
    return ''
}

export const formatCurrency = (value: number, type = 'float') => {
    if (type === 'integer') {
        return numeral(value).format('R0,0')
    }

    return numeral(value).format('R0,0.00')
}

export const addSeparator = (num: string) =>
    num.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const currencyFormat = (num: any, symbol: string) =>
    `${symbol} ${addSeparator(Number(num).toFixed(2))}`

export const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const camelCaseToSpaces = (input: string): string => {
    const spacedString = input.replace(/([a-z])([A-Z])/g, '$1 $2')
    return spacedString
}

export const formatReduxRTKMutation = (message: string) => {
    const formatCamelCase = camelCaseToSpaces(message)

    const actionMapping = {
        update: 'Updating ',
        create: 'Creating ',
        delete: 'Deleting ',
        read: 'Reading ',
        generate: 'Generating ',
        email: 'Emailing ',
        sync: 'Syncing ',
        reset: 'Resetting ',
    }

    const foundAction = Object.keys(actionMapping).find((action) =>
        message.includes(action)
    )

    if (foundAction) {
        const updatedString = formatCamelCase.replace(
            foundAction,
            actionMapping[foundAction as keyof typeof actionMapping]
        )
        return updatedString
    }

    return formatCamelCase
}

export const formatNumber = (
    value: number,
    type = 'float',
    utilityType?: UtilityType
) => {
    if (utilityType) {
        switch (utilityType) {
            case UtilityType.WATER:
            case UtilityType.GAS:
            case UtilityType.ELECTRICITY:
                return Math.round((value / 1000) * 1000) / 1000
            default:
                break
        }
    }

    if (type === 'integer') {
        return numeral(value).format('0,0', Math.floor)
    }

    return numeral(value).format('0,0.00', Math.floor)
}

export const roundUsage = (
    num: number,
    decimals: number,
    utilityType?: UtilityType
) => {
    const factorOfTen = Math.pow(10, decimals)
    return formatNumber(
        Math.round(num * factorOfTen) / factorOfTen,
        'float',
        utilityType
    )
}

export const getUtilityUnits = (utilityType?: UtilityType, kilo?: boolean) => {
    switch (utilityType) {
        case UtilityType.WATER:
            if (kilo) {
                return 'kL'
            }

            return 'L'

        case UtilityType.ELECTRICITY:
            if (kilo) {
                return 'kWh'
            }

            return 'Wh'

        case UtilityType.GAS:
            if (kilo) {
                return 'm3'
            }

            return 'cm3'

        default:
            return 'unit'
    }
}

export const getFlatRateUnit = (
    ratePerUnit: number,
    utilityType?: UtilityType,
    unit?: string
) => {
    if (unit) {
        return `R ${(ratePerUnit * 1000).toFixed(2)} per k${unit}`
    } else {
        return `R ${(ratePerUnit * 1000).toFixed(2)} per ${getUtilityUnits(
            utilityType,
            true
        )}`
    }
}

export const formatDateRange = (
    dateRangeFilter: DateRangeType,
    startDate: string,
    endDate: string
) => {
    const dateRangeInfo = DateRangeOptions.find(
        (dateRangeInfo) => dateRangeInfo._id === dateRangeFilter
    )

    if (!dateRangeInfo) {
        return 'Loading...'
    }

    if (dateRangeInfo.name === 'Custom') {
        return `${dayjs(startDate).format('D MMM YYYY')} to ${dayjs(
            endDate
        ).format('D MMM YYYY')}`
    }

    return dateRangeInfo.name
}

export const getYAxisLabel = (type: UtilityType) => {
    if (type === UtilityType.WATER) {
        return 'Consumption (kL)'
    }
    if (type === UtilityType.ELECTRICITY) {
        return 'Consumption (kWh)'
    }

    if (type === UtilityType.GAS) {
        return 'Consumption (m3)'
    }
    return 'Consumption (Units)'
}

export const getIoTStateDescription = (iotState: IoTMeterState) => {
    if (
        iotState?.reported?.R === iotState?.desired?.R ||
        iotState?.desired?.R == null
    ) {
        return iotState?.reported?.R === true ? 'On' : 'Off'
    }

    if (iotState?.reported?.R === false && iotState?.desired?.R === true) {
        return 'Attempting to switch on'
    }

    if (iotState?.reported?.R === true && iotState?.desired?.R === false) {
        return 'Attempting to switch off'
    }
    return 'Unknown'
}

export const flattenObject = (ob: Record<any, any>) => {
    const result: Record<any, any> = {}

    for (const i in ob) {
        if (
            typeof ob[i] === 'object' &&
            !Array.isArray(ob[i]) &&
            Object.prototype.toString.call(ob[i]) !== '[object Date]'
        ) {
            const temp = flattenObject(ob[i])
            for (const j in temp) {
                result[i + '.' + j] = temp[j]
            }
        } else {
            result[i] = ob[i]
        }
    }
    return result
}

export function removeFieldsFromObject(
    obj: any,
    fieldsToRemove: string[]
): any {
    // Helper function to recursively traverse and clean the object
    const cleanObject = (currentObj: any): any => {
        if (currentObj === null || typeof currentObj !== 'object') {
            return currentObj
        }

        // Check if it's an array, handle each element recursively
        if (Array.isArray(currentObj)) {
            return currentObj.map((item) => cleanObject(item))
        }

        // Create a shallow copy of the current object
        const newObj = {} as any

        for (const key in currentObj) {
            // Only add fields that are not in the fieldsToRemove array
            if (!fieldsToRemove.includes(key)) {
                newObj[key] = cleanObject(currentObj[key])
            }
        }

        return newObj
    }

    return cleanObject(obj)
}
