import {
    HighlightOff,
    KeyboardArrowLeft,
    KeyboardArrowRight,
} from '@mui/icons-material'
import {
    Breakpoint,
    CardHeader,
    CardMedia,
    DialogActions,
    MobileStepper,
    useTheme,
} from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import * as React from 'react'
import { useEffect, useState } from 'react'

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

interface StepData {
    label: string
    content: JSX.Element
    disableNext?: boolean
    hideNext?: boolean
    media?: string
    title?: string
    subtitle?: string
}

export interface StepperDialogProps {
    open: boolean
    onClose: () => void
    steps: StepData[]
    maxWidth?: Breakpoint | undefined
    onSubmit: () => void
    manualStep?: number
    setManualStep?: React.Dispatch<React.SetStateAction<number | undefined>>
}

export default function StepperDialogMobile({
    open,
    onClose,
    steps,
    maxWidth,
    manualStep,
    setManualStep,
}: StepperDialogProps) {
    const theme = useTheme()
    const [activeStep, setActiveStep] = useState(0)

    const handleBack = () => {
        setManualStep && setManualStep(undefined)
        setActiveStep((prevStep) => prevStep - 1)
    }

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            onClose()
        } else {
            setManualStep && setManualStep(undefined)
            setActiveStep((prevStep) => prevStep + 1)
        }
    }

    useEffect(() => {
        if (manualStep !== undefined) {
            setActiveStep(manualStep)
        }
    }, [manualStep])

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            maxWidth={maxWidth ?? 'lg'}
            fullWidth
        >
            {steps[activeStep].media && (
                <CardMedia
                    image={steps[activeStep].media}
                    component="img"
                    sx={{ maxHeight: 350 }}
                />
            )}
            {steps[activeStep].title && (
                <CardHeader
                    title={steps[activeStep].title}
                    subheader={steps[activeStep].subtitle}
                />
            )}
            <DialogContent sx={{ minHeight: '550px', height: '750px', mt: 3 }}>
                {steps[activeStep].content}
            </DialogContent>
            <DialogActions>
                <MobileStepper
                    variant="dots"
                    steps={steps.length}
                    position="static"
                    activeStep={activeStep}
                    sx={{ flexGrow: 1 }}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === 5}
                        >
                            {activeStep === steps.length - 1
                                ? 'Close'
                                : steps[activeStep + 1].label}
                            {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                            ) : activeStep === steps.length - 1 ? (
                                <HighlightOff sx={{ marginLeft: 1 }} />
                            ) : (
                                <KeyboardArrowRight />
                            )}
                        </Button>
                    }
                    backButton={
                        <Button
                            size="small"
                            onClick={handleBack}
                            hidden={true}
                            disabled={activeStep === 0}
                        >
                            {activeStep !== 0 &&
                                (theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                ))}

                            {activeStep !== 0 && steps[activeStep - 1].label}
                        </Button>
                    }
                />
            </DialogActions>
        </Dialog>
    )
}
