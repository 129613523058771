import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTheme } from '@mui/material'
import { CLICK_UP_LINKS, MOBILE_APP_LINKS } from 'src/constants'

interface Question {
    id: number
    question: string
    answer: JSX.Element
}

const PageFive: React.FC = () => {
    const theme = useTheme()

    const questions: Question[] = [
        {
            id: 4,
            question: 'How do I use the platform?',
            answer: (
                <Typography>
                    Please watch the demo video in this tour tutorial in
                    addition to reading through the support guides. Furthermore,
                    understanding the Switch Energy ecosystem entities and
                    relations does help navigating the platform.
                </Typography>
            ),
        },
        {
            id: 1,
            question: 'How do I set up an application?',
            answer: (
                <Typography component="div">
                    An application should be setup in the following order:
                    <br />
                    <br />
                    <ul style={{ margin: 0, paddingLeft: 16 }}>
                        <li>
                            <Typography variant="body1">
                                Create an application
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                Create your desired utilities ( water,
                                electricity, etc )
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                Create desired accounts
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                Assign a tariff to your utility ( you may need
                                to create your desired tariff )
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                Create meterpoints ensuring they are assigned to
                                the correct utility and account
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                Assign the correct meters to the relevant meter
                                point
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                If you are going to perform billing, create a
                                billing run to allow for pre-paid or post-paid
                                revenue collection.
                            </Typography>
                        </li>
                    </ul>
                    <br />
                    NB: A complete application setup flow where a user can
                    simply submit a spreadsheet with all their application
                    information is currently being developed.
                </Typography>
            ),
        },
        {
            id: 3,
            question: 'What meters does Switch Energy support?',
            answer: (
                <Typography>
                    Switch Energy supports direct integration with the following
                    meter brands: <br /> <br />
                    <ul style={{ margin: 0, paddingLeft: 16 }}>
                        <li>
                            <Typography variant="body1">
                                Electricity: Honeywell, Eastron, Landis & Gyr
                                and SMAPPEE
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                Water: Kamstrup, Visiosoft, Amber IoT, MACROCOMM
                                and ZZ-Metering
                            </Typography>
                        </li>
                    </ul>
                    <br />
                    Switch Energy further supports any meter hosted on the
                    following 3rd-party platforms via webhook integration:{' '}
                    <br /> <br />
                    <ul style={{ margin: 0, paddingLeft: 16 }}>
                        <li>
                            <Typography variant="body1">Livewire</Typography>
                        </li>
                        <li>
                            <Typography variant="body1">PNP SCADA</Typography>
                        </li>
                        <li>
                            <Typography variant="body1">ND-Metering</Typography>
                        </li>
                    </ul>
                </Typography>
            ),
        },
        {
            id: 5,
            question: 'What tariffs are supported?',
            answer: (
                <Typography>
                    A variety of municipal tariffs are supported and managed by
                    Switch Energy which are updated every year. Please search
                    the "Tariffs" table for the desired tariffs. If a municipal
                    tariff is missing please reach out for it to be added.
                    Furthermore, users can create their own custom tariffs as
                    required.
                </Typography>
            ),
        },
        {
            id: 6,
            question: 'How can I add a user to the platform or mobile app?',
            answer: (
                <Typography>
                    Please see the dedicated user management{' '}
                    <Typography
                        component="a"
                        href="https://play.google.com/store/apps/details?id=za.org.switchenergy.mobile"
                        style={{
                            color: theme.colors.primary.main,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                    >
                        guide
                    </Typography>{' '}
                    for the process of onboarding and managing users.
                </Typography>
            ),
        },
        {
            id: 7,
            question: 'How can I collect revenue from tenants / clients?',
            answer: (
                <Typography>
                    Switch Energy offers pre-paid revenue collection via the the
                    mobile application available for{' '}
                    <Typography
                        component="a"
                        href={MOBILE_APP_LINKS.ANDROID}
                        style={{
                            color: theme.colors.primary.main,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                    >
                        Android
                    </Typography>{' '}
                    or{' '}
                    <Typography
                        component="a"
                        href={MOBILE_APP_LINKS.IOS}
                        style={{
                            color: theme.colors.primary.main,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                    >
                        IOS
                    </Typography>{' '}
                    and post-paid revenue collection via generating payable
                    invoices.
                </Typography>
            ),
        },
        {
            id: 8,
            question: 'Does Switch Energy offer energy trading / wheeling?',
            answer: (
                <Typography>
                    Yes, Switch Energy does offer a separate energy trading
                    component built on top of the existing smart metering
                    platform. Please reach out for further information.
                </Typography>
            ),
        },
        {
            id: 9,
            question: 'Are custom integrations supported?',
            answer: (
                <Typography>
                    Yes, if you require integrations with meters or other 3rd
                    party platforms that are not currently supported please
                    reach out for further discussion.
                </Typography>
            ),
        },
        {
            id: 10,
            question:
                'There is a certain feature that I require, can I request that it be added to the platform?',
            answer: (
                <Typography>
                    Yes, please reach out for custom development needs.
                </Typography>
            ),
        },
    ]
    return (
        <div>
            {questions.map((item) => (
                <Accordion key={item.id}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${item.id}-content`}
                        id={`panel${item.id}-header`}
                    >
                        <Typography>{item.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{item.answer}</AccordionDetails>
                </Accordion>
            ))}
        </div>
    )
}

export default PageFive
