//@ts-nocheck
import { Box, useTheme, SxProps } from '@mui/material'
import logo_long from 'logo_long.png'

//TODO this is wrong obviously
export enum LogoVariant {
    PRIMARY = `/static/images/brand/${process.env.REACT_APP_BRAND}/Logo_green_switch.svg`,
    SECONDARY = `/static/images/brand/${process.env.REACT_APP_BRAND}/Logo_green_switch.svg`,
    TERTIARY = `/static/images/brand/${process.env.REACT_APP_BRAND}/Logo_white_switch.svg`,
    MONOCHROME = 'monochrome',
    INVERTED = 'inverted',
    MINIMAL = 'minimal',
}

interface ILogoInterface {
    height?: number | string
    width?: number | string
    variant?: LogoVariant
    sxProps?: SxProps
}

function Logo(props: ILogoInterface) {
    const {
        height = 'auto',
        width = 'auto',
        variant = LogoVariant.PRIMARY,
    } = props

    const theme = useTheme()

    return (
        <Box sx={props.sxProps}>
            <img src={logo_long} alt="Logo" height={height} width={width} />
        </Box>
    )
}

export default Logo
