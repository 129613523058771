import { createSlice } from '@reduxjs/toolkit'
import { persistAuth } from 'src/auth/utils/persistAuth'
import { resetUser } from 'src/auth/utils/resetUser'
import { NetworkState } from 'src/constants/network-state'
import { RootState } from '../store'
import { IUserSlice } from './userSlice.contracts'

const initialState: IUserSlice = {
    user: null,
    status: NetworkState.NOT_STARTED,
    accessToken: null,
    refreshToken: null,
    error: null,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        /**
         * Dispatch resetUser to reset the redux store. Handy when clearing user sessions when logging out.
         */
        resetUser(state, action) {
            console.log('resetting store')
        },
    },
    extraReducers(builder) {
        builder
            //########################### Persist Auth ###########################
            .addCase(persistAuth.pending, (state, action) => {
                state.status = NetworkState.LOADING
            })
            .addCase(persistAuth.fulfilled, (state, action) => {
                state.accessToken = action.payload.accessToken
                state.refreshToken = action.payload.refreshToken
                state.user = action.payload.user
                state.error = action.payload.error
                state.status = NetworkState.SUCCESS
            })
            .addCase(persistAuth.rejected, (state, action) => {
                state.accessToken = null
                state.refreshToken = null
                state.user = null
                state.error = action.error.message
                state.status = NetworkState.ERROR
            })
            //########################### Reset User ###########################
            .addCase(resetUser.pending, (state, action) => {
                state.status = NetworkState.LOADING
            })
            .addCase(resetUser.fulfilled, (state, action) => {
                state.status = NetworkState.NOT_STARTED
                state.accessToken = null
                state.refreshToken = null
                state.user = null
                state.error = null
            })
            .addCase(resetUser.rejected, (state, action) => {
                state.status = NetworkState.ERROR
                state.accessToken = null
                state.refreshToken = null
                state.user = null
                state.error = action.error.message
            })
    },
})

export default userSlice.reducer

export const selectUser = (state: RootState): typeof initialState => state.user
