import { createAsyncThunk } from '@reduxjs/toolkit'
import { IUserSlice } from 'src/redux/user/userSlice.contracts'

export const persistAuth = createAsyncThunk(
    'user/persistAuth',
    async (params: { userAuth: IUserSlice }, { rejectWithValue }) => {
        try {
            return params.userAuth
        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
)

export const signOutAuth = createAsyncThunk(
    'user/signOut',
    async (_data: {}, { rejectWithValue }) => {
        try {
            return
        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
)
